import { createIcon } from "@chakra-ui/icon"
import * as React from "react"

export const CheckIcon = createIcon({
  viewBox: "0 0 14 14",
  path: (
    <g fill="currentColor">
      <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" />
    </g>
  ),
})
